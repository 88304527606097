import { useMediaQuery } from 'usehooks-ts'

import { Metadata } from '@redwoodjs/web'

import GroupsDataGridCell from 'src/components/GroupsDataGridCell'
import NavTabs from 'src/components/NavTabs/NavTabs'
import PageHeader from 'src/components/PageHeader/PageHeader'

const SettingsGroupsPage = () => {
  const isMobile = useMediaQuery('(max-width: 640px)')
  const tabs = [
    {
      name: 'Members',
      href: '/app/settings/user-management/users',
      current: false,
    },
    {
      name: 'Groups',
      href: '/app/settings/user-management/groups',
      current: true,
    },
  ]

  return (
    <>
      <Metadata title="Group Management" description="Group Management page" />
      <div className="h-screen">
        <PageHeader title="Group Management" />
        {!isMobile && <NavTabs tabs={tabs} />}
        <GroupsDataGridCell />
      </div>
    </>
  )
}

export default SettingsGroupsPage
